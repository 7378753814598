
import { Component, Vue, namespace, State } from 'nuxt-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { getAuth, signInWithEmailAndPassword, RecaptchaVerifier, getMultiFactorResolver, AuthErrorCodes, MultiFactorResolver, FactorId, PhoneAuthProvider, Auth, User } from 'firebase/auth'
import { Scope } from '@sentry/core'
import signinQuery from '~/queries/signin.gql'
import { SigninMutation, SigninMutationVariables } from '~/types/eldamar'
import InputField from '~/components/form/input-field.vue'
import Logo from '~/components/element/logo.vue'
import Notifications from '~/components/layout/notifications.vue'
import { emitError, getRedirectFrom, isFirebaseError, isFirebaseMultiFactorAuthError, isGraphQLForbiddenError, removeRedirectFrom } from '~/utils/nuxt-helper'
import InputCode from '~/components/signin/input-code.vue'

const notificationStore = namespace('notifications')
const sellerStore = namespace('seller')

@Component({
  layout: 'single',
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  components: {
    InputField,
    Logo,
    Notifications,
    InputCode,
  },
  middleware: ['authenticated-signin'],
})
export default class extends Vue {
  @notificationStore.Mutation
  private readonly refreshMessages!: () => void

  @notificationStore.Mutation
  private readonly setForceReset!: (p: boolean) => void

  @notificationStore.Mutation
  private readonly refreshInstants!: () => void

  @State
  private readonly brandId!: string

  @State
  private readonly tenantId!: string

  @sellerStore.Action('getDefaultPage')
  private readonly getDefaultPage!: () => Promise<string>

  private email = ''
  private password = ''
  private error = ''
  private loading = false
  private rv: RecaptchaVerifier | null = null
  private isFirstForm = true

  private verificationId = ''
  private multiFactorResolver: MultiFactorResolver | null = null

  get emailErrors (): string[] {
    const errors: string[] = []
    if (!this.$v.email.$dirty) {
      return errors
    }
    !this.$v.email.required && errors.push(this.$i18n.t('validation.required', { target: 'メールアドレス' })?.toString())
    !this.$v.email.email && errors.push(this.$i18n.t('validation.pattern', { target: 'メールアドレス' })?.toString())
    return errors
  }

  get emailClasses (): string[] {
    const classes: string[] = []
    if (this.$v.email.$dirty && this.$v.email.$invalid) {
      classes.push('border-warning')
    }
    return classes
  }

  get passwordErrors (): string[] {
    const errors: string[] = []
    if (!this.$v.password.$dirty) {
      return errors
    }
    !this.$v.password.required && errors.push(this.$i18n.t('validation.required', { target: 'パスワード' })?.toString())
    return errors
  }

  get passwordClasses (): string[] {
    const classes: string[] = []
    if (this.$v.password.$dirty && this.$v.password.$invalid) {
      classes.push('border-warning')
    }
    return classes
  }

  get memberId (): string {
    const id = this.$route.query.member
    return typeof id === 'string' ? id : ''
  }

  get isAutify (): boolean {
    return this.$route.query.z8p8jr5 !== undefined
  }

  async mounted (): Promise<void> {
    if (!this.isAutify) {
      await this.recaptureContainerInitialize()
    }
  }

  private async recaptureContainerInitialize (): Promise<void> {
    const auth = getAuth()
    if (this.$store.state.tenantId) {
      auth.tenantId = this.$store.state.tenantId
    }

    this.rv = new RecaptchaVerifier(auth, 'recapture-container', {
      size: 'invisible',
      'error-callback': (e: Error) => {
        if (e === undefined) {
          // error起きてもundefinedじゃ何もわからんので握りつぶす
          return
        }
        const scope = new Scope()
        scope.setExtra('message-stringify', JSON.stringify(e))
        this.$sentry.captureException(e, scope)
        this.$nuxt.error({ statusCode: 500 })
      },
    })

    try {
      await this.rv.render()
    } catch (e) {
      emitError(this.$nuxt, e)
    }
  }

  private async onSubmit (): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    this.loading = true

    if (!this.isAutify) {
      const token = await this.rv?.verify()
      if (!token) {
        this.loading = false
        return
      }
    }

    this.setForceReset(true)
    this.refreshInstants()
    this.error = ''
    this.signin()
  }

  private retryCount = 0

  async signin (): Promise<void> {
    const auth = getAuth()
    try {
      await signInWithEmailAndPassword(auth, this.email, this.password)
    } catch (e) {
      if (!isFirebaseError(e)) {
        emitError(this.$nuxt, e)
        return
      }
      // Firebase Authenticator関連でIndexedDBの接続エラーが起きることがある。
      // 再現方法や明示的な解決方法が現状ないため、IDBDatabaseのエラーをキャッチして/signinに飛ばすようにする
      if (e.message.indexOf('IDBDatabase') !== -1) {
        window.location.href = '/signin'
        return
      }
      switch (e.code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          this.error = this.$i18n.t('signin.error.accountFailure')?.toString()
          break
        case 'auth/too-many-requests':
          this.error = this.$i18n.t('signin.error.tooManyRequest')?.toString()
          break
        case AuthErrorCodes.MFA_REQUIRED:
          if (!isFirebaseMultiFactorAuthError(e)) { break }
          try {
            const res = await this.checkTwoFactor(auth, getMultiFactorResolver(auth, e))
            if (res) {
              this.isFirstForm = false
              this.loading = false
            } else {
              this.error = this.$i18n.t('signin.twoFactor.error.sendFailure')?.toString()
              this.loading = false
            }
            return
          } catch (e) {
            if (!isFirebaseError(e)) {
              emitError(this.$nuxt, e)
              return
            }
            if (e.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
              this.error = this.$i18n.t('signin.error.tooManyRequest')?.toString()
              this.loading = false
              return
            }
            emitError(this.$nuxt, e)
          }
          break
        case AuthErrorCodes.NETWORK_REQUEST_FAILED:
          if (this.retryCount > 2) {
            this.retryCount = 0
            this.error = this.$i18n.t('signin.error.networkError')?.toString()
            break
          }
          this.retryCount++
          await this.signin()
          return
        default:
          emitError(this.$nuxt, e)
          this.loading = false
          return
      }
      this.loading = false
      return
    }
    const user = auth.currentUser
    if (!user) {
      emitError(this.$nuxt, new Error('firebase user not found'))
      this.loading = false
      return
    }

    await this.signinBackEnd(user)
    await this.initializeApp()
    await this.goAppPage()
  }

  private async onSuccessMultifactorCode (user: User): Promise<void> {
    await this.signinBackEnd(user)
    await this.initializeApp()
    await this.goAppPage()
  }

  private onBack (): void {
    this.reset()
  }

  private onExpired (): void {
    this.reset()
  }

  private reset (): void {
    this.isFirstForm = true
    this.verificationId = ''
    this.multiFactorResolver = null
    this.loading = false
  }

  private async signinBackEnd (user: User): Promise<void> {
    try {
      const token = await user.getIdToken(true)
      const res = await this.$apollo.mutate<SigninMutation, SigninMutationVariables>({
        mutation: signinQuery,
        variables: {
          idToken: token,
          brandId: this.brandId,
          memberId: this.memberId,
        },
      })
      if (!res.data?.signin) {
        emitError(this.$nuxt, new Error(`failed to sign in uid:${user.uid}`))
        this.loading = false
        return
      }
      this.$sentry.setUser({ id: res.data.signin.customUserId })
    } catch (e) {
      if (!this.brandId) {
        const scope = new Scope()
        scope.setLevel('warning')
        this.$sentry.captureException(e, scope)
        this.$nuxt.error({ statusCode: 500 })
        return
      }
      if (isFirebaseError(e) && e.code === AuthErrorCodes.TOKEN_EXPIRED) {
        this.error = '画面を更新して再度ログインしてください。'
        this.reset()
        return
      }
      if (isGraphQLForbiddenError(e as Error)) {
        this.error = 'アクセスできません'
        this.reset()
        return
      }
      emitError(this.$nuxt, e)
      this.loading = false
    }
  }

  private async initializeApp (): Promise<void> {
    try {
      await this.$store.dispatch('seller/set', null, { root: true })
    } catch (e) {
      if (isGraphQLForbiddenError(e as Error)) {
        this.error = 'アクセスできません'
        this.reset()
        return
      }
      emitError(this.$nuxt, e)
    }
  }

  private async goAppPage (): Promise<void> {
    const before = getRedirectFrom(this.$nuxt)
    if (before && !before.includes('signin')) {
      removeRedirectFrom(this.$nuxt)
      location.href = before
      return
    }

    try {
      const defaultPage = await this.getDefaultPage()
      this.$router.push(defaultPage)
    } catch (e) {
      if (isGraphQLForbiddenError(e as Error)) {
        this.error = 'アクセスできません'
        this.reset()
        return
      }
      emitError(this.$nuxt, e)
    }
  }

  private async checkTwoFactor (auth: Auth, resolver: MultiFactorResolver): Promise<boolean> {
    if (!this.rv) {
      return false
    }

    const hint = resolver.hints.find(h => h.factorId === FactorId.PHONE)
    if (hint) {
      const info = {
        multiFactorHint: hint,
        session: resolver.session,
      }
      const authProvider = new PhoneAuthProvider(auth)
      this.verificationId = await authProvider.verifyPhoneNumber(info, this.rv)
      this.multiFactorResolver = resolver
      return true
    }

    return false
  }
}
